<div style="flex:1 0 auto;webkit-box-flex:1;">
  <app-navigation-bar></app-navigation-bar>
  <router-outlet></router-outlet>
  <app-messages></app-messages>
</div>

<!--
<footer>
  <app-footer></app-footer>
</footer>-->

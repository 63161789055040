<h3>Top Heroes</h3>
<div class="grid grid-pad">
  <a *ngFor="let character of characters" class="col-1-4"
     routerLink="/backstory/{{character.id}}">
    <div class="module character">
      <h4>{{character.name}}</h4>
    </div>
  </a>
</div>

<app-character-search></app-character-search>
<div>
  <div class="sidebar">Sidebar Div</div>
  <div class="primary-content">
    <h2>Michael Tinning</h2>
    <div class="float-child" style="width:25%;height:50%;">
      <p>
        Hi, Hey There, Hello! I'm a computer engineer and web designer from south Texas specializing in learning as well as maintaining a plethora of hobbies. While design holds most of my interest, I have more experience as a QA, especially in regards to automation testing. But this site wasn't built for that purpose. My personal website was built from the ground up to house all of the ideas and projects I have bursting forth from my imagination. And if it can serve as a portfolio for work, well, all the better!
      </p>
      <p>
        On the left, you'll find a sidebar with information on the current page. Here, there might not be too much to populate it, but it will update automatically with each page you visit here for ease of navigation. Clicking on a header will bring you to that part of the page, similarly to how the table of contents on Wikipedia works. The top nav bar will help you navigate between the pages of the site. I like to think of them like the wings of a castle. Each link will bring you to a new wing, but you'll still be in the one castle. Which is fitting, because this site is built with Angular, meaning it's a single page application, requiring no loading of new pages for navigation. Instead, the content of the page will be changed according to the wing you're in. 
      </p>
      <p>
        Anyways, I hope you enjoy exploring the site! I try to update it regularly so all the ideas I have don't get lost or crowd up my brain too much. If you like the feel of it and want to tell me how easy it is on the eyes, feel free to reach me at my business email! 
      </p>
    </div>
    <div class="float-child" id="picture-section">
      <img src="assets/img/Self.png">
    </div>
  </div>
  <div style="height:800px"></div>
</div>
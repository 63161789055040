<div class="primary-content"> 
  <h2>New Encounter Generator</h2>
  <div>
    <form> 
      <table>
        <tr>
          <th>Party Size</th>
          <th>Party Level</th>
          <th>Party Health</th>
          <th>Party Damage</th>
          <th>Difficulty</th>
          <th>Number of Foes</th>
        </tr>
        <tr>
          <td><input placeholder="Number of Party Members"></td>
          <td><input placeholder="Average Party Level"></td>
          <td><input placeholder="Average Party Health"></td>
          <td><input placeholder="Average DPR"></td>
          <td>
            <select placeholder="Desired Difficulty">
              <option>Trivial</option>
              <option>Easy</option>
              <option>Moderate</option>
              <option>Difficult</option>
              <option>Deadly</option>
              <option>Impossible</option>
            </select>
          </td>
          <td><input type="number" placeholder="Lowest Count"> | <input type="number" placeholder="Highest Count"></td>
        </tr>
      </table>
    </form>
  </div>
  <div>
    <!-- OUTPUT -->
  </div>
</div>
